$primary: #fdf6e3;
$secondary: #268bd2;
$third: #eee8d5;

$primary-darker: darken($primary, 33%);
$secondary-darker: darken($secondary, 33%);

$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee

$font-family-monospace: "iA Writer Duo", Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-sans-serif: "iA Writer Quattro", -apple-system, system-ui,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$text-color: #073642;

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-container {
  flex: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .my-4;
  color: $secondary;
}

.container img {
  @extend .img-fluid;
  @extend .mx-auto;
}

a {
  @extend .text-secondary;
}

hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: fade-out($gray-darker, 0.9);
}

.self-conception li {
  margin: 10px 0;
}
