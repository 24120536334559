#mainNav {
  border-color: fade-out($gray-darker, .95);
  background-color: $secondary;
  .navbar-brand {
    font-family: $font-family-monospace;
    font-size: 1.4em;
    color: $primary !important;
    &:hover,
    &:focus {
      color: $primary-darker !important;
    }
  }
  .navbar-toggler {
    font-size: 1.2em;
    padding: 8px 10px;
    color: $primary;
  }
  .navbar-nav {
    > li {
      > a {
        font-family: $font-family-monospace;
        font-size: 1.2em;
        color: $primary !important;
        &.active {
          color: $primary-darker !important;
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
        }
        &:hover {
          text-decoration: underline !important;
        }
      }
      > a,
      > a:focus {
        color: $primary !important;
        &:hover {
          color: $primary-darker !important;
        }
      }
    }
  }
  @media (min-width: 768px) {
    border-color: transparent;
    background-color: transparent;
    .navbar-brand {
      color: $secondary !important;
      &:hover,
      &:focus {
        color: $secondary-darker !important;
      }
    }
    .navbar-nav {
      > li {
        > a {
          color: $secondary !important;
          &.active {
            color: $secondary-darker !important;
            background-color: transparent;
            &:hover {
              background-color: transparent;
            }
          }
        }
        > a,
        > a:focus {
          color: $secondary !important;
          &:hover {
            color: $secondary-darker !important;
          }
        }
      }
    }
    &.navbar-opaque {
      border-color: fade-out($gray-darker, .9);
      background-color: $secondary;
      .navbar-brand {
        color: $primary !important;
        &:hover,
        &:focus {
          color: $primary-darker !important;
        }
      }
      .navbar-nav {
        > li {
          > a {
            &.active {
              color: $primary-darker !important;
            }
          }
          > a,
          > a:focus {
            color: $primary !important;
            &:hover {
              color: $primary-darker !important;
            }
          }
        }
      }
    }
  }
}
