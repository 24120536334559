header.masthead {
    position: relative;

    width: 100%;
    padding-top: 150px;
    padding-bottom: 100px;
    
    .container {
      max-width: 680px;
    }

    .logo-container {
      img {
        max-width: 100px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 10px;
      }

      h1 {
        font-size: 2.8em !important;
        color: $text-color !important;
      }

      font-size: 1.3em;
      text-align: center;

      @media (min-width: 576px) {
        text-align: right;
      }
    }

    h2 {
      color: $text-color !important;
    }
    .email {
      font-weight: bold !important;
    }

    div.header-content {
      padding-left: 10px;
      padding-right: 10px;
    }
}

img.border {
  border-width: 2px !important;
  border-color: $secondary !important;
}

div.border {
  border-width: 0px !important;

  @media (min-width: 576px) {
    border-width: 5px !important;
    border-color: $secondary !important;
  }
}
