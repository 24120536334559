.page-footer {
  background-color: $third;
}

.footer-nav {
  background-color: $secondary;
}

.footer-nav a {
  color: $primary !important;
  &.active {
    color: $primary-darker !important;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
  &:hover {
    color: $primary-darker !important;
    text-decoration: underline !important;
  }
  &:focus {
    color: $primary !important;
    &:hover {
      color: $primary-darker !important;
    }
  }
}
