section {
    padding: 100px 0; 
    section-heading {
      margin-bottom: 10px;
      padding-bottom: 50px;
    }
    section-item {
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 50px;
    }
}
